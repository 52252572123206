import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Header from "../components/Header";
import mbbuybg from "../Images/mbbuybg.png";
import win from "../Images/win.png";
import oly from "../Images/oly.png";
import { StyledBtn } from "../components/SmallComponents/AppComponents";
import Footer from "./Footer";

export default function Win() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Box
        sx={{
          backgroundImage: matches ? `url(${mbbuybg})` : `url(${win})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100%",
        }}
      >
        <Header />
        <Container maxWidth="lg">
          <Typography
            pt={8}
            sx={{
              fontFamily: "accelerator",
              fontSize: matches ? "40px" : "72px",
              textAlign: "center",
            }}
          >
            <span
              style={{
                background: "transparent",
                backgroundImage:
                  "linear-gradient(91.5deg, #E8749E 28.38%, #F0B90B 82.14%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Win BIG and Beyond
            </span>{" "}
          </Typography>
          <Typography
            mt={2}
            sx={{
              fontFamily: "Poppins",
              fontSize: matches ? "14px" : "23px",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis. Class
            aptent taciti sociosqu ad litora torquent per conubia nostra, per
            inceptos himenaeos.
          </Typography>
          <Typography
            mt={4}
            pb={4}
            sx={{
              fontFamily: "Poppins",
              fontSize: matches ? "14px" : "32px",
              textAlign: "center",
              fontWeight: "900",
            }}
          >
            <span
              style={{
                background: "transparent",
                backgroundImage:
                  "linear-gradient(91.5deg, #E8749E 28.38%, #F0B90B 82.14%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Gorem ipsum dolor sit amet, consectetur adipiscing elit.
            </span>{" "}
          </Typography>
          <Box
            mt={7}
            mb={matches ? 7 : 20}
            sx={{
              background: "#00000040",
              border: "6px solid #F0B90B",
              borderRadius: "40px",
              width: "100%",
              height: "100%",
              py: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={oly} width={matches ? "100%" : "60%{"} alt="" />
            <StyledBtn
              width={matches ? "80%" : "412px"}
              style={{
                color: "#fff",
                borderRadius: "20px",
                height: "60px",
                marginTop: matches && "20px",
              }}
            >
              Open Competiton Link
            </StyledBtn>
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  );
}
