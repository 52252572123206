import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import buybg from "../Images/buybg.png";
import mbbuybg from "../Images/mbbuybg.png";
import cards from "../Images/cards.png";
import { FaCircle } from "react-icons/fa";

import Header from "../components/Header";
import Footer from "./Footer";
import Faqs from "./Faqs";

export default function Buy() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Box
        sx={{
          backgroundImage: matches ? `url(${mbbuybg})` : `url(${buybg})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100%",
        }}
      >
        <Header />
        <Container maxWidth="lg">
          <Typography
            pt={8}
            sx={{
              fontFamily: "accelerator",
              fontSize: matches ? "32px" : "72px",
              textAlign: "center",
            }}
          >
            <span
              style={{
                background: "transparent",
                backgroundImage:
                  "linear-gradient(91.5deg, #E8749E 28.38%, #F0B90B 82.14%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              How to Buy
            </span>{" "}
          </Typography>
          <Grid container spacing={5} my={5} display="flex" alignItems="center">
            <Grid item xs={12} md={6}>
              <img src={cards} width={matches ? "100%" : "90%"} alt="" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontFamily: "accelerator",
                  fontSize: matches ? "30px" : "50px",
                  textAlign: "left",
                }}
              >
                <span
                  style={{
                    background: "transparent",
                    backgroundImage:
                      "linear-gradient(91.5deg, #E8749E 28.38%, #F0B90B 82.14%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Lorem Ipsum
                </span>{" "}
              </Typography>
              <Typography
                mt={2}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: matches ? "16px" : "17px",
                  color: "#FFFFFF",
                  textAlign: "left",
                }}
              >
                Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis.
                Class aptent taciti sociosqu ad litora torquent per conubia
                nostra, per inceptos himenaeos.
              </Typography>
              <Typography
                mt={5}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: matches ? "16px" : "17px",
                  color: "#FFFFFF",
                  textAlign: "left",
                  display: "flex",
                }}
              >
                <FaCircle
                  style={{
                    color: "#fff",
                    fontSize: "15px",
                    marginRight: "15px",
                    marginTop: "5px",
                  }}
                />{" "}
                Gorem ipsum dolor sit amet, consectetur adipiscing elit
                consectetur adipiscing elit.
              </Typography>
              <Typography
                mt={3}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: matches ? "16px" : "17px",
                  color: "#FFFFFF",
                  textAlign: "left",
                  display: "flex",
                }}
              >
                <FaCircle
                  style={{
                    color: "#fff",
                    fontSize: "15px",
                    marginRight: "15px",
                    marginTop: "5px",
                  }}
                />{" "}
                Gorem ipsum dolor sit amet, consectetur adipiscing elit
                consectetur adipiscing elit.
              </Typography>
              <Typography
                mt={3}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: matches ? "16px" : "17px",
                  color: "#FFFFFF",
                  textAlign: "left",
                  display: "flex",
                }}
              >
                <FaCircle
                  style={{
                    color: "#fff",
                    fontSize: "15px",
                    marginRight: "15px",
                    marginTop: "5px",
                  }}
                />{" "}
                Gorem ipsum dolor sit amet, consectetur adipiscing elit
                consectetur adipiscing elit.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Faqs />
        <Footer />
      </Box>
    </>
  );
}
