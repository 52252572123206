import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Header from "../components/Header";
import mbbuybg from "../Images/mbbuybg.png";
import contact from "../Images/contact.png";
import { StyledButton } from "../components/SmallComponents/AppComponents";
import Footer from "./Footer";

export default function ContactUs() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Box
        sx={{
          backgroundImage: matches ? `url(${mbbuybg})` : `url(${contact})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100%",
        }}
      >
        <Header />
        <Container maxWidth="lg">
          <Typography
            pt={8}
            sx={{
              fontFamily: "accelerator",
              fontSize: matches ? "40px" : "72px",
              textAlign: "center",
            }}
          >
            <span
              style={{
                background: "transparent",
                backgroundImage:
                  "linear-gradient(91.5deg, #E8749E 28.38%, #F0B90B 82.14%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Get In touch with us
            </span>{" "}
          </Typography>
          <Typography
            mt={2}
            sx={{
              fontFamily: "Poppins",
              fontSize: matches ? "14px" : "23px",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis. Class
            aptent taciti sociosqu ad litora torquent per conubia nostra, per
            inceptos himenaeos.
          </Typography>
          <Typography
            mt={4}
            pb={4}
            sx={{
              fontFamily: "Poppins",
              fontSize: matches ? "14px" : "32px",
              textAlign: "center",
              fontWeight: "900",
            }}
          >
            <span
              style={{
                background: "transparent",
                backgroundImage:
                  "linear-gradient(91.5deg, #E8749E 28.38%, #F0B90B 82.14%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Gorem ipsum dolor sit amet, consectetur adipiscing elit.
            </span>{" "}
          </Typography>

          <Grid container spacing={5} mt={5}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: matches ? "18px" : "22px",
                  color: "#FFFFFF",
                  textAlign: "left",
                }}
              >
                Your Name
              </Typography>
              <Box
                mt={2}
                sx={{
                  bgcolor: "transparent",
                  border: "2px solid #F0B90B",
                  borderRadius: "18px",
                  width: "100%",
                  height: "65px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    height: "40px",
                    width: "100%",
                    fontSize: "17px",
                    fontWeight: "500",
                    textAlign: "left",
                    color: "#fff",
                    fontFamily: "Poppins",
                    backgroundColor: "transparent",
                    paddingLeft: "15px",
                    border: "none",
                    outline: "none",
                  }}
                  type="text"
                  placeholder="Type your name here"
                  // value={enteredAmount}
                  // onChange={onChangeHandler}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: matches ? "18px" : "22px",
                  color: "#FFFFFF",
                  textAlign: "left",
                }}
              >
                Your Email
              </Typography>
              <Box
                mt={2}
                sx={{
                  bgcolor: "transparent",
                  border: "2px solid #F0B90B",
                  borderRadius: "18px",
                  width: "100%",
                  height: "65px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    height: "40px",
                    width: "100%",
                    fontSize: "17px",
                    fontWeight: "500",
                    textAlign: "left",
                    color: "#fff",
                    fontFamily: "Poppins",
                    backgroundColor: "transparent",
                    paddingLeft: "15px",
                    border: "none",
                    outline: "none",
                  }}
                  type="text"
                  placeholder="Type your email here"
                  // value={enteredAmount}
                  // onChange={onChangeHandler}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: matches ? "18px" : "22px",
                  color: "#FFFFFF",
                  textAlign: "left",
                }}
              >
                Your Message
              </Typography>
              <Box
                mt={2}
                sx={{
                  p: 2,
                  bgcolor: "transparent",
                  border: "2px solid #F0B90B",
                  borderRadius: "18px",
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <textarea
                  placeholder="Leave your question or comment here"
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    textAlign: "left",
                    color: "#fff",
                    fontFamily: "Poppins",
                    backgroundColor: "transparent",
                    width: "100%",
                    border: "none",
                    outline: "none",
                    height: "100%",
                  }}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                mt={5}
                mb={matches ? 5 : 12}
              >
                <StyledButton
                  width={matches ? "80%" : "300px"}
                  style={{ height: "50px", color: "#fff" }}
                >
                  Send Message
                </StyledButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </Box>
    </>
  );
}
